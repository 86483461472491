import { Button } from '@/components/shared/Button'
import { TrustpilotLogo } from '@/components/shared/TrustpilotLogo'
import { Review, TrustpilotReview } from '@/components/shared/TrustpilotReview'
import { TrustpilotStarRating } from '@/components/shared/TrustpilotStarRating'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { Section } from '@/lib/gtm/typesV2'
import Link from 'next/link'
import styled from 'styled-components'

export type Trustpilot = {
  title: string
  allReviewsLink: string
  allReviewsLinkLabel: string
  trustpilotRating: number
  reviews: Review[]
}

interface ReviewsProps {
  trustpilot: Trustpilot
  style?: string
  ctaIdentifier?: string
  ctaSection: Section
  reviewLength?: number
}

const StyledReviews = styled.section`
  background: ${(props) => props.theme.v2.onSurface.text};
  padding: 80px 0;

  &.white {
    background: ${(props) => props.theme.v2.surface.white};
    .trustpilot-header h2 {
      color: ${(props) => props.theme.v2.onSurface.text};
    }
    .side-text {
      color: ${(props) => props.theme.v2.onSurface.text};
    }
  }

  &.cream {
    background: ${(props) => props.theme.v2.surface.cream500};
    .trustpilot-header h2 {
      color: ${(props) => props.theme.v2.onSurface.text};
    }
    .side-text {
      color: ${(props) => props.theme.v2.onSurface.text};
    }
  }

  .trustpilot-header {
    margin: 0 auto;
    text-align: center;
  }

  .trustpilot-header div:first-of-type {
    margin-bottom: 20px;
    justify-content: center;
  }

  .trustpilot-header h2 {
    font-family: ${(props) => props.theme.v2.font.nantes};
    font-size: ${(props) => props.theme.v2.typography.display.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.s.lineHeight};
    color: ${(props) => props.theme.v2.surface.white};
  }

  .side-text {
    color: ${(props) => props.theme.v2.surface.white};
  }

  .review-gallery {
    margin: 40px 0;
    padding: 0;
    width: 100%;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      max-height: 1110px;
      overflow: hidden;
    }
  }

  .review-gallery ul {
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    text-align: center;
  }

  .review-gallery li {
    margin: 8px;
    white-space: normal;
    text-align: left;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    .trustpilot-header {
      width: 30%;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    .review-gallery {
      overflow: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }

    .review-gallery li {
      display: inline-block;
      width: 327px;
      max-width: 357px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 30px 30px 0px 0px;
    padding: 56px 24px;

    .trustpilot-header h2 {
      font-size: ${(props) => props.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xxs.lineHeight};
    }

    .review-gallery li {
      margin: 16px auto;
      max-width: 327px;
    }
  }

  &#alt-style {
    padding: 20px 0;

    @media screen and (min-width: 661px) {
      .review-gallery {
        display: inline-block;
        overflow: none;
        align-items: left;
      }

      .review-gallery li {
        width: 300px;
      }

      .review-gallery ul li:nth-of-type(1n + 4) {
        display: none;
      }
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      .review-gallery ul {
        display: flex !important;
        margin-left: 30px;
        overflow: scroll;
        overflow-y: hidden;
      }

      .review-gallery li {
        flex: 0 0 300px !important;
        margin-right: 10px;
      }
    }
  }
`

const ButtonContainer = styled.div`
  text-align: center;
`

const trackButtonClick = (ctaSection: Section, btnName: string, ctaIdentifier?: string) => {
  const cta = ctaIdentifier ? `${btnName} - ${ctaIdentifier}` : btnName
  trackCtaClick(ctaSection, cta)
}

export const TrustpilotSection = ({ trustpilot, style, ctaIdentifier, ctaSection, reviewLength }: ReviewsProps) => {
  const altStyle = style ? `alt-style` : ''
  return (
    <StyledReviews id={altStyle} className={style}>
      <div className="trustpilot-header">
        <h2>{trustpilot.title}</h2>

        <TrustpilotStarRating rating={parseFloat(process.env.TRUSTPILOT_RATING_SCORE || '4.3')}>
          {process.env.TRUSTPILOT_RATING_TEXT}
        </TrustpilotStarRating>

        <TrustpilotLogo size="large" colour={altStyle ? 'dark' : 'white'} />
      </div>
      {!!trustpilot.reviews.length && (
        <div className={`review-gallery ${altStyle}`}>
          <ul>
            {trustpilot.reviews.map((review, index) => (
              <li key={index}>
                <TrustpilotReview review={review} style={style} reviewLength={reviewLength} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <ButtonContainer>
        {altStyle ? (
          <Link
            href={trustpilot.allReviewsLink}
            onClick={() => trackButtonClick(ctaSection, trustpilot.allReviewsLinkLabel, ctaIdentifier)}
            target="_blank"
          >
            Read more on Trustpilot
          </Link>
        ) : (
          <Button
            url={trustpilot.allReviewsLink}
            text={trustpilot.allReviewsLinkLabel}
            onClick={() => trackButtonClick(ctaSection, trustpilot.allReviewsLinkLabel, ctaIdentifier)}
            target="_blank"
            style="primary"
          />
        )}
      </ButtonContainer>
    </StyledReviews>
  )
}
