import React, { CSSProperties, ReactNode } from 'react'
import dynamic from 'next/dynamic'

const DynamicLottie = dynamic(() => import('lottie-react'), { ssr: false })

interface LottieAnimationProps {
  ariaLabel: string
  role: string
  source: any
  style?: CSSProperties
  className?: string
}

export const LottieAnimation = ({ ariaLabel, role, source, style, className }: LottieAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
  }

  return (
    <DynamicLottie
      {...defaultOptions}
      animationData={source}
      style={style}
      aria-label={ariaLabel}
      role={role}
      className={className}
    />
  )
}
